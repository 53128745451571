<template>
  <main class="view-pdf">
    <component v-bind="componentData" :is="nameComp" />
  </main>
</template>

<script>
import generateShareCopy from '@/mixins/generateShareCopy'
import { bondsService } from '@/services'
import dateFormat from '@/mixins/dateFormat.js'

export default {
  name: 'ViewPdf',
  components: {
    contractPdf: () => import('@/components/contracts/ContractPdf.vue'),
    taxInvoicePdf: () => import('@/components/TaxInvoices/taxInvoicePdf'),
    bondPdf: () => import('@/components/bonds/BondPdf.vue'),
    reportPdf: () => import('@/components/reports/ReportDetails')
  },
  mixins: [generateShareCopy, dateFormat],
  data() {
    return {
      nameComp: null,
      pdfData: null,
      type: null,
      pdfMedia: {},
      pdfEnvConfig: {},
      bondHeader: [
        { text: 'البند', value: 'clause', align: 'center', sortable: false },
        {
          text: 'فتره الإستحقاق',
          align: 'center',
          sortable: false,
          value: 'duePeriod'
        },
        { text: 'المبلغ', value: 'amount', align: 'center', sortable: false },
        {
          text: 'قيمة الضريبة',
          value: 'taxAmount',
          align: 'center',
          sortable: false
        },
        { text: 'الإجمالي', value: 'total', align: 'center', sortable: false },
        { text: 'العداد', value: 'meter', align: 'center', sortable: false }
      ]
    }
  },
  computed: {
    componentData() {
      return {
        clickedReport: this.pdfData,
        item: this.pdfData,
        tax: this.pdfData,
        headers: this.bondHeader,
        toBePrint: this.pdfData,
        ref: 'PDF',
        viewSharedPdf: true,
        sharedPdfMedia: this.pdfMedia,
        pdfEnvConfig: this.pdfEnvConfig,
        bonsSubtypes: this.bonsSubtypes
      }
    },
    duePeriod() {
      return `${this.formatDate(
        this.pdfData?.installmentsDate?.startDate
      )} - ${this.formatDate(this.pdfData?.installmentsDate?.endDate)}`
    },
    bonsSubtypes() {
      return [
        {
          clause: this.pdfData?.subType?.nameAr ?? '',
          duePeriod: this.duePeriod,
          amount: this.pdfData?.bondValue ?? '',
          taxAmount: this.pdfData?.taxAmount ?? '',
          total: this.pdfData?.totalAmount ?? '',
          meter:
            this.pdfData?.contract?.[`${this.pdfData?.subType?.code}Meter`] ??
            '-'
        }
      ]
    }
  },
  async created() {
    this.type = this.$route.query.type
    if (this.type.includes('التقرير')) {
      const { data, component } = this.$route.query
      this.pdfData = JSON.parse(data)
      this.nameComp = component
    } else {
      const { entityData, media, env } = await this.getShareCopy({
        service: bondsService
      })
      this.pdfData = entityData
      this.pdfMedia = media || {}
      this.pdfEnvConfig = env || {}
      this.pdfData && (this.nameComp = this.$route.query.component)
    }
  },
  metaInfo() {
    return {
      title: `بي دي اف ${this.type}`
    }
  }
}
</script>

<style lang="scss">
.view-pdf {
  background-color: #2b2b2b;
  min-height: 100vh;
  & .whiteSide {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
  & [class*='col-'] {
    flex: 1 1 100%;
  }
}
</style>
